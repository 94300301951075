/**
 * Why did we need css file?
 * Dynamic style change causes custom fonts to be re-requested from the server
 * ref: https://github.com/styled-components/styled-components/issues/1593
 */
@font-face {
  font-family: RionaSans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/med-tek_rionasans-regular-webfont.woff2')
      format('woff2'),
    url('/public/fonts/med-tek_rionasans-regular-webfont.woff') format('woff'),
    url('/public/fonts/med-tek_rionasans-regular-webfont.ttf') format('ttf');
}

@font-face {
  font-family: RionaSans;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/public/fonts/med-tek_rionasans-medium-webfont.woff2')
      format('woff2'),
    url('/public/fonts/med-tek_rionasans-medium-webfont.woff') format('woff'),
    url('/public/fonts/med-tek_rionasans-medium-webfont.ttf') format('ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/Roboto-Regular.woff2') format('woff2'),
    url('/public/fonts/Roboto-Regular.woff') format('woff'),
    url('/public/fonts/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/public/fonts/Roboto-Bold.woff2') format('woff2'),
    url('/public/fonts/Roboto-Bold.woff') format('woff'),
    url('/public/fonts/Roboto-Bold.ttf') format('ttf');
}

body {
  font-family: 'RionaSans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
}
